var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gt-nav__sub-container",style:({
    'max-height': ("" + _vm.innerHeight),
    height: '100%'
  })},[(_vm.data.navigationIcon)?_c('div',{staticClass:"img-nav"},[_c('UAImage',{staticClass:"gt-image-container",staticStyle:{"max-width":"200px","max-height":"500px"},attrs:{"src":_vm.data.navigationIcon}})],1):_vm._e(),_vm._v(" "),_c('section',{staticClass:"gt-nav__sub-label",style:({ 'justify-content': 'flex-start' })},[(
        _vm.data.children &&
        _vm.data.children.length &&
        _vm.data.children.filter(function (item) {
          return item.styleId === '1';
        }).length
      )?[_vm._l((_vm.data.children.filter(function (item) {
          return item.styleId === '1';
        })),function(item,index){return [_c('div',{key:item.navigationCode,staticClass:"gt-nav__sub-item gt-nav__sub-item__left",staticStyle:{"width":"calc((100% - 8%) * 0.2)","min-width":"240px","margin":"33px 48px 48px 4%"}},[(item.navigationImageList.length)?_c('gt-link',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
              click_type: '悬浮',
              navigation_primary_category: _vm.categoryPrimaryName,
              navigation_secondary_category: item.navigationTitle
            }),expression:"{\n              click_type: '悬浮',\n              navigation_primary_category: categoryPrimaryName,\n              navigation_secondary_category: item.navigationTitle\n            }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
              click_type: '点击',
              navigation_primary_category: _vm.categoryPrimaryName,
              navigation_secondary_category: item.navigationTitle
            }),expression:"{\n              click_type: '点击',\n              navigation_primary_category: categoryPrimaryName,\n              navigation_secondary_category: item.navigationTitle\n            }",arg:"selectType"}],staticClass:"has-link",attrs:{"to":item.navigationImageList[0].imageLink || item.navigationUrl || ''}},[_c('UAImage',{staticClass:"nav-type-swiper-item__img",staticStyle:{"width":"100%"},attrs:{"src":((item.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp/quality,Q_20"),"alt":item.navigationImageList[0].imageCode}})],1):_vm._e(),_vm._v(" "),_c('gt-link',{class:{
              'gt-nav__sub-item-label': true,
              'has-link': !item.navigationImageList
            },attrs:{"to":item.navigationUrl || ''}},[_vm._t("sub-title",function(){return [_c('span',{style:({
                  color: JSON.parse(item.extendParams).color,
                  margin: '20px 0 12px',
                  'font-size': '20px'
                }),on:{"click":function($event){return _vm.handleSub(item)}}},[_vm._v("\n                "+_vm._s(item.navigationTitle)+"\n              ")])]},{"data":item})],2),_vm._v(" "),_c('div',{staticClass:"gt-nav__sub-link"},[_c('ul',{class:{
                'gt-nav__sub-link-wrap': true,
                'has-image': item.thirdHasImage
              }},_vm._l((item.children),function(child){return _c('li',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                  click_type: '悬浮',
                  navigation_primary_category: _vm.categoryPrimaryName,
                  navigation_secondary_category: item.navigationTitle,
                  navigation_third_category: child.navigationTitle
                }),expression:"{\n                  click_type: '悬浮',\n                  navigation_primary_category: categoryPrimaryName,\n                  navigation_secondary_category: item.navigationTitle,\n                  navigation_third_category: child.navigationTitle\n                }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                  click_type: '点击',
                  navigation_primary_category: _vm.categoryPrimaryName,
                  navigation_secondary_category: item.navigationTitle,
                  navigation_third_category: child.navigationTitle
                }),expression:"{\n                  click_type: '点击',\n                  navigation_primary_category: categoryPrimaryName,\n                  navigation_secondary_category: item.navigationTitle,\n                  navigation_third_category: child.navigationTitle\n                }",arg:"selectType"}],key:child.navigationCode,staticClass:"gt-nav__sub-link-item",staticStyle:{"height":"20px"},on:{"click":function($event){return _vm.handleSub(child)}}},[_c('gt-link',{class:{
                    'gt-nav__sub-link-label': true,
                    'has-link': child.navigationUrl != ''
                  },attrs:{"to":child.navigationUrl || ''}},[(child.navigationIcon != '')?_c('UAImage',{staticClass:"gt-image-container nav-icon",attrs:{"src":child.navigationIcon}}):_vm._e(),_vm._v(" "),_vm._t("link-label",function(){return [_c('div',{staticClass:"add-content-right"},[_c('span',{style:({
                          color: JSON.parse(child.extendParams).color,
                          'font-size': '14px !important'
                        })},[_vm._v("\n                        "+_vm._s(child.navigationTitle)+"\n                      ")]),_vm._v(" "),_c('i',{staticClass:"iconfont icon-a-16-1px-right-s",style:({
                          color: JSON.parse(child.extendParams).color
                        })})])]},{"data":child})],2)],1)}),0)])],1)]})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"gt-nav__sub-item gt-nav__sub-item__right"},[_c('div',{staticClass:"gt-nav__sub-item__right-container",style:({ 'padding-right': _vm.innerPadding })},[(_vm.data.children && _vm.data.children.length)?[_vm._l((_vm.data.children),function(item,index){return [(index > 0)?[(item.styleId === '2' || item.styleId === '6' || item.styleId === '7')?[_c('div',{key:index,staticClass:"type-1"},[_c('div',{staticClass:"type-1__header"},[_c('span',{style:({ color: JSON.parse(item.extendParams).color }),on:{"click":function($event){return _vm.handleSub(item)}}},[_vm._v("\n                      "+_vm._s(item.navigationTitle)+"\n                    ")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":{ path: item.navigationUrl || '' }}},[(item.styleId !== '7' && item.navigationUrl)?_c('div',{staticClass:"type-1__all"},[_c('span',{style:({
                            color: JSON.parse(item.extendParams).color
                          })},[_vm._v("\n                          查看全部\n                        ")]),_vm._v(" "),_c('img',{staticStyle:{"margin-top":"1px"},attrs:{"src":require("@/assets/ua-icon24/icon-arrow-right-small.svg"),"alt":"icon-arrow-right-small"}})]):_vm._e()])],1),_vm._v(" "),_c('div',{class:{
                      'type-1__container': item.styleId === '2',
                      'type-3__container': item.styleId === '6',
                      'type-7__container': item.styleId === '7'
                    }},[_vm._l((item.children),function(otherItem,index){return [_c('div',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                          click_type: '悬浮',
                          navigation_primary_category: _vm.categoryPrimaryName,
                          navigation_secondary_category: otherItem.navigationTitle
                        }),expression:"{\n                          click_type: '悬浮',\n                          navigation_primary_category: categoryPrimaryName,\n                          navigation_secondary_category: otherItem.navigationTitle\n                        }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                          click_type: '点击',
                          navigation_primary_category: _vm.categoryPrimaryName,
                          navigation_secondary_category: otherItem.navigationTitle
                        }),expression:"{\n                          click_type: '点击',\n                          navigation_primary_category: categoryPrimaryName,\n                          navigation_secondary_category: otherItem.navigationTitle\n                        }",arg:"selectType"}],key:index,class:{
                          'type-1__container-item': item.styleId === '2',
                          'type-3__container-item': item.styleId === '6',
                          'item-2_width': item.children.length === 2 && item.styleId === '2',
                          'type-7__container-item': item.styleId === '7'
                        },style:({
                          color: JSON.parse(otherItem.extendParams).color
                        })},[_c('nuxt-link',{key:index,staticClass:"box_high",staticStyle:{"height":"100%","width":"100%","display":"flex","align-items":"center"},attrs:{"to":{
                            path: otherItem.navigationUrl || ''
                          }}},[_c('UAImage',{staticClass:"gt-image-container",attrs:{"src":((item.styleId === '2'
                                ? otherItem.navigationIcon
                                : otherItem.navigationImageList.length &&
                                  otherItem.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp/quality,Q_20"),"alt":otherItem.navigationTitle}}),_vm._v(" "),(item.styleId !== '7')?_c('span',[_vm._v("\n                            "+_vm._s(otherItem.navigationTitle)+"\n                          ")]):_vm._e()],1)],1)]})],2)])]:_vm._e(),_vm._v(" "),(item.styleId === '5')?[_c('section',{key:index,staticStyle:{"margin-bottom":"32px"},on:{"mouseleave":function($event){return _vm.handleMouseleave('right')}}},[_c('p',{staticClass:"type-1__header",staticStyle:{"height":"20px","align-items":"center","margin-bottom":"16px"}},[_vm._v("\n                    "+_vm._s(item.navigationTitle)+"\n                  ")]),_vm._v(" "),_c('GtSwiper',{ref:"swiper2",refInFor:true,staticClass:"brand-type4-swiper",attrs:{"data":item.brandList || [],"swiper-option":_vm.type4SwiperOption},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var data = ref.data;
return _c('div',{ref:"swiperDom",refInFor:true,staticClass:"swiper-detail",on:{"mousemove":function($event){return _vm.handleMousemove('right')}}},_vm._l((data),function(item2,index2){return _c('div',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                          click_type: '悬浮',
                          navigation_primary_category: item2.navigationTitle
                        }),expression:"{\n                          click_type: '悬浮',\n                          navigation_primary_category: item2.navigationTitle\n                        }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                          click_type: '点击',
                          navigation_primary_category: item2.navigationTitle
                        }),expression:"{\n                          click_type: '点击',\n                          navigation_primary_category: item2.navigationTitle\n                        }",arg:"selectType"}],key:index2,staticClass:"swiper-detail__item"},[_c('nuxt-link',{attrs:{"to":{
                            path: item2.navigationUrl || ''
                          }}},[_c('UAImage',{staticClass:"gt-image-container",attrs:{"src":((item2.navigationImageList.length &&
                              item2.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp/quality,Q_20"),"alt":item2.navigationTitle}})],1)],1)}),0)}}],null,true)},[_vm._v(" "),_c('div',{attrs:{"slot":"pagination"},slot:"pagination"},[_c('div',{ref:"nextRight",refInFor:true,staticClass:"swiperj-button-next"},[_c('i',{staticClass:"iconfont icon-arrow-right"})]),_vm._v(" "),_c('div',{ref:"prevRight",refInFor:true,staticClass:"swiperj-button-prev"},[_c('i',{staticClass:"iconfont icon-arrow-left"})]),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})])])],1),_vm._v(" "),(item.advertList)?_c('section',{key:index,staticClass:"style5-advert"},_vm._l((item.advertList),function(advertItem){return _c('div',{key:advertItem.advertCode,staticClass:"style5-advert_item"},[(advertItem.advertData)?[_c('SeriesAdert',{attrs:{"data":advertItem}})]:_vm._e()],2)}),0):_vm._e()]:_vm._e()]:_vm._e()]})]:_vm._e(),_vm._v(" "),(_vm.data.brandType && _vm.data.brandType.length)?[_c('section',[_c('div',{staticClass:"brand-type__top"},[_vm._l((_vm.data.brandType),function(item,index){return [_c('div',{key:index,staticClass:"brand-type__top-item",class:{
                    'brand-type__top-active':
                      _vm.chooseBanerIndex === index && _vm.data.brandType.length > 1,
                    'brand-type__top__high': _vm.data.brandType.length === 1
                  },on:{"click":function($event){_vm.chooseBanerIndex = index}}},[_vm._v("\n                  "+_vm._s(item.navigationTitle)+"\n                ")])]})],2),_vm._v(" "),(
                _vm.data.brandType[_vm.chooseBanerIndex].children &&
                _vm.data.brandType[_vm.chooseBanerIndex].children.length
              )?_c('div',{staticClass:"brand-type__bottom",style:({
                'flex-wrap': _vm.data.brandType[_vm.chooseBanerIndex].styleId === '3' ? 'wrap' : 'nowrap'
              })},[_vm._l((_vm.data.brandType[_vm.chooseBanerIndex].children),function(item,index){return [_c('div',{directives:[{name:"hover-track",rawName:"v-hover-track:selectType",value:({
                    click_type: '悬浮',
                    navigation_primary_category: _vm.categoryPrimaryName,
                    navigation_secondary_category: item.navigationTitle
                  }),expression:"{\n                    click_type: '悬浮',\n                    navigation_primary_category: categoryPrimaryName,\n                    navigation_secondary_category: item.navigationTitle\n                  }",arg:"selectType"},{name:"click-track",rawName:"v-click-track:selectType",value:({
                    click_type: '点击',
                    navigation_primary_category: _vm.categoryPrimaryName,
                    navigation_secondary_category: item.navigationTitle
                  }),expression:"{\n                    click_type: '点击',\n                    navigation_primary_category: categoryPrimaryName,\n                    navigation_secondary_category: item.navigationTitle\n                  }",arg:"selectType"}],key:index,staticClass:"brand-type__bottom-item",class:{
                    'brand-type__bottom-item': _vm.data.brandType[_vm.chooseBanerIndex].styleId === '4',
                    'brand-type__bottom-brand': _vm.data.brandType[_vm.chooseBanerIndex].styleId === '3'
                  },style:({
                    color: JSON.parse(item.extendParams).color
                  })},[_c('nuxt-link',{attrs:{"to":{ path: item.navigationUrl || '' }}},[(_vm.data.brandType[_vm.chooseBanerIndex].styleId === '4')?_c('UAImage',{staticClass:"gt-image-container",attrs:{"src":((item.navigationIcon) + "?x-oss-process=image/format,webp/quality,Q_20"),"alt":item.navigationTitle}}):_c('UAImage',{staticClass:"gt-image-container",attrs:{"src":((item.navigationImageList.length && item.navigationImageList[0].imageUrl) + "?x-oss-process=image/format,webp/quality,Q_20"),"alt":item.navigationTitle}}),_vm._v(" "),(_vm.data.brandType[_vm.chooseBanerIndex].styleId === '4')?_c('p',[_vm._v("\n                      "+_vm._s(item.navigationTitle)+"\n                    ")]):_vm._e()],1)],1)]})],2):_vm._e()])]:_vm._e()],2)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }