var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hotWords.length)?_c('div',[_c('div',{staticClass:"hot-word-search__header"},[_c('p',{style:({
        'font-size': _vm.isMobile ? '14px' : '14px'
      })},[_vm._v("\n      热门搜索\n    ")]),_vm._v(" "),(_vm.isMobile ? _vm.hotWords.length > 6 : _vm.hotWords.length > 5)?_c('div',{staticClass:"qiehuan-box",on:{"click":_vm.changeList}},[_c('span',[_vm._v("换一换")]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/image/qiehuan-change.svg"),"alt":"qiehuan-change"}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"word"},_vm._l((_vm.showList),function(item,index){return _c('nuxt-link',{directives:[{name:"click-track",rawName:"v-click-track",value:({
        eventName: 'researchButtonClick',
        data: {
          reseach_button: '顶部搜索',
          key_word: item.hotword,
          key_word_type: '热门搜索'
        },
        extraCacheData: {
          key_word_type: '热门搜索'
        },
        beforeTrack: function () {
          _vm.$trackHelp.setUserActiveData('key_word_list', function (arr) { return arr.concat( [item.hotword]); });
        }
      }),expression:"{\n        eventName: 'researchButtonClick',\n        data: {\n          reseach_button: '顶部搜索',\n          key_word: item.hotword,\n          key_word_type: '热门搜索'\n        },\n        extraCacheData: {\n          key_word_type: '热门搜索'\n        },\n        beforeTrack: () => {\n          $trackHelp.setUserActiveData('key_word_list', arr => [...arr, item.hotword]);\n        }\n      }"}],key:index,attrs:{"to":item.linkUrl}},[_c('div',{staticClass:"word-box"},[(item.imageUrl)?_c('UAImage',{staticStyle:{"width":"48px"},attrs:{"src":((item.imageUrl) + "?x-oss-process=image/format,webp"),"alt":item.hotword,"aspect-ratio":"4/5"}}):_vm._e(),_vm._v(" "),_c('span',{class:{
            'hotword-no-img-width': !item.imageUrl
          }},[_vm._v("\n          "+_vm._s(item.hotword)+"\n        ")])],1)])}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }