var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"navBox",staticClass:"gt-nav"},[(_vm.showMore)?_c('div',{staticClass:"more-btn-box",staticStyle:{"margin-right":"32px"},on:{"mousemove":_vm.backFn}},[_c('img',{attrs:{"src":require("@/assets/ua-icon24/icon-arrow-left-small-white.svg"),"alt":"icon-arrow-right-small"}}),_vm._v(" "),_c('span',{staticClass:"nav-more"},[_vm._v("返回")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.navData),function(item,index){return _c('div',{key:(index + "_" + (item.navigationCode)),ref:"$nav_item",refInFor:true,class:{
      'gt-nav__item': true,
      'gt-nav__item__children': item.children && item.children.length,
      'gt-nav__item-fill': item.level === 3,
      active: item.navigationCode === _vm.activeNavCode,
      'has-image': item.thirdHasImage
    },on:{"mouseenter":function($event){return _vm.mouseEnter($event, item)},"click":_vm.handleClick}},[_c('div',{staticClass:"gt-nav__item-content"},[_c('gt-link',{directives:[{name:"hover-track",rawName:"v-hover-track",value:({
          eventName: 'selectType',
          data: {
            click_type: '悬浮',
            navigation_primary_category: item.navigationTitle
          }
        }),expression:"{\n          eventName: 'selectType',\n          data: {\n            click_type: '悬浮',\n            navigation_primary_category: item.navigationTitle\n          }\n        }"},{name:"click-track",rawName:"v-click-track",value:({
          eventName: 'selectType',
          data: {
            click_type: '点击',
            navigation_primary_category: item.navigationTitle
          },
          extraCacheData: {
            source_navigation: ("" + (item.navigationTitle))
          },
          beforeTrack: function () {
            _vm.$sensors.registerPage({
              source_first_nav: item.navigationTitle,
              source_navigation: item.navigationTitle
            });
          },
          registerPage: {
            source_first_nav: item.navigationTitle,
            source_navigation: item.navigationTitle
          }
        }),expression:"{\n          eventName: 'selectType',\n          data: {\n            click_type: '点击',\n            navigation_primary_category: item.navigationTitle\n          },\n          extraCacheData: {\n            source_navigation: `${item.navigationTitle}`\n          },\n          beforeTrack: () => {\n            $sensors.registerPage({\n              source_first_nav: item.navigationTitle,\n              source_navigation: item.navigationTitle\n            });\n          },\n          registerPage: {\n            source_first_nav: item.navigationTitle,\n            source_navigation: item.navigationTitle\n          }\n        }"}],class:{
          'gt-nav__item-label': true,
          'is-active':
            _vm.active(item) ||
            item.navigationCode === _vm.clickNav.navigationCode ||
            item.navigationUrl === _vm.$route.path ||
            item.navigationUrl === _vm.$route.fullPath
        },attrs:{"data-id":item.navigationCode,"to":item.navigationUrl || ''},on:{"click":function($event){return _vm.clickFn(index, item)}}},[_vm._t("title",function(){return [(_vm.isMobile)?_c('i',{staticClass:"gt-nav__icon iconfont icon-a-24-1px-right-s"}):_vm._e(),_vm._v(" "),_c('span',{staticStyle:{"border":"none"}},[_vm._v(_vm._s(item.navigationTitle))])]},{"data":item})],2),_vm._v(" "),(
          item.navigationCode === _vm.activeNavCode &&
          item.children &&
          item.children.length &&
          item.navigationTitle !== '探索'
        )?_c('div',{ref:"subNav",refInFor:true,class:{
          'gt-nav__sub': true,
          'has-image': item.thirdHasImage,
          'gt-nav__sub-fill': item.level === 3,
          'sub-hidden': _vm.subHidden
        }},[(_vm.defer(index * 2))?_c('div',{staticClass:"gt-nav__sub-wrap"},[_c('linkSubNav',{ref:"$subnav",refInFor:true,attrs:{"category-primary-name":item.navigationTitle,"data":item}})],1):_vm._e()]):_vm._e()],1)])}),_vm._v(" "),(!_vm.showMore)?_c('div',{staticClass:"more-btn-box",on:{"mousemove":_vm.moreFn}},[_c('span',{staticClass:"nav-more",staticStyle:{"margin-left":"32px"}},[_vm._v("更多")]),_vm._v(" "),_c('img',{attrs:{"src":require("@/assets/image/right-small-white.svg"),"alt":"icon-arrow-right-small"}})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }